/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'

const iconDefault = css`
  fill: none;
  stroke: white;
  stroke-width: 2;
  transition: stroke 0.2s;
  cursor: pointer;

  :hover {
    stroke: #ffa503;
  }
`

const logoDark = css`
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    font-size: 20px;
    letter-spacing: 2px;
    color: var(--color-base);
    margin-left: 8px;
    transition: color 0.125s ease;
    user-select: none;
  }

  svg {
    stroke: var(--color-base);
  }

  @media (hover: hover) {
    :hover {
      svg {
        stroke: var(--color-secondary);
      }
      span {
        color: var(--color-secondary);
      }
    }
  }

  :active {
    svg {
      stroke: var(--color-secondary-dark);
    }
    span {
      color: var(--color-secondary-dark);
    }
  }
`

const logoLight = css`
  ${logoDark}

  span {
    color: white;
  }

  svg {
    stroke: white;
  }
`

const IconBrand = props => {
  const { width = 32, height = 32, light, onClick } = props
  const dataTestId = props['data-testid'] || 'icon-brand'

  return (
    <span css={light ? logoLight : logoDark}>
      <svg
        css={iconDefault}
        width={width}
        height={height}
        onClick={onClick}
        data-testid={dataTestId}
        viewBox="0 0 100 100"
      >
        <rect width="100" height="100" rx="20" fill="white" />
        <path
          fill="var(--color-primary)"
          stroke="var(--color-primary)"
          d="M53.83 63.00L53.83 63.00Q53.19 63.45 52.16 63.81Q51.13 64.17 49.86 64.17L49.86 64.17Q48.42 64.17 47.30 63.72Q46.17 63.27 45.72 62.28L45.72 62.28Q40.86 52.20 37.36 43.83Q33.84 35.46 31.68 29.97L31.68 29.97L31.32 29.97Q31.05 36.72 30.74 42.53Q30.43 48.33 30.06 53.87Q29.70 59.40 29.34 65.07Q28.98 70.74 28.63 77.22L28.63 77.22Q28.09 77.41 27.23 77.58Q26.38 77.77 25.38 77.77L25.38 77.77Q23.41 77.77 22.59 76.95Q21.79 76.14 21.79 74.61L21.79 74.61Q21.79 73.71 21.92 70.74Q22.05 67.77 22.32 63.54Q22.59 59.31 22.91 54.18Q23.23 49.05 23.63 43.74Q24.04 38.43 24.44 33.30Q24.84 28.17 25.20 23.94L25.20 23.94Q25.93 23.31 27.27 22.77Q28.63 22.23 30.34 22.23L30.34 22.23Q34.48 22.23 36.09 25.56L36.09 25.56Q37.80 29.07 39.56 32.99Q41.31 36.90 43.07 40.95Q44.83 45.00 46.49 48.96Q48.16 52.92 49.69 56.52L49.69 56.52L50.05 56.52Q52.03 51.75 54.00 47.12Q55.98 42.48 57.88 38.16Q59.77 33.84 61.47 30.06Q63.19 26.28 64.53 23.13L64.53 23.13Q65.34 22.68 66.61 22.46Q67.86 22.23 68.94 22.23L68.94 22.23Q73.17 22.23 73.80 25.11L73.80 25.11Q74.16 26.73 74.61 30.47Q75.06 34.20 75.52 39.06Q75.97 43.92 76.37 49.50Q76.77 55.08 77.14 60.26Q77.50 65.44 77.77 69.80Q78.03 74.16 78.22 76.68L78.22 76.68Q76.59 77.77 74.44 77.77L74.44 77.77Q73.08 77.77 72.05 77.09Q71.02 76.41 70.83 74.61L70.83 74.61Q70.39 68.04 70.03 61.56Q69.66 55.08 69.35 49.23Q69.03 43.38 68.77 38.48Q68.50 33.57 68.31 30.15L68.31 30.15L67.95 30.15Q66.97 32.67 65.66 35.82Q64.36 38.97 62.60 42.93Q60.84 46.89 58.69 51.89Q56.53 56.88 53.83 63.00Z"
        />
      </svg>
      <span>MultiLocale</span>
    </span>
  )
}

export default IconBrand
