/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import getCookie from '@monorepo/shared/getCookie.js'
import { useTranslation } from '@multilocale/react/index.js'
import getAppUrl from '@multilocale/shared/getAppUrl.js'
import IconBrand from '@multilocale/shared/IconBrand.js'
import NavigationComponentStiloso from '@stiloso/components/NavigationComponent.js'
import languages from '../languages.js'
import product from '../product.js'

const NavigationComponent = ({ locale }) => {
  const { t } = useTranslation(locale)
  let showDashboard = !!getCookie(product + 'AccessToken')

  return (
    <NavigationComponentStiloso
      locale={locale}
      AppIcon={<IconBrand light />}
      appUrl={getAppUrl()}
      appName={t('Globo')}
      languages={languages}
      showDashboard={showDashboard}
    />
  )
}

export default NavigationComponent
